import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      // Remove user data from localStorage
      localStorage.removeItem("user_data");
      localStorage.removeItem("userId");

      // Confirm that user data has been removed
      if (
        !localStorage.getItem("user_data") &&
        !localStorage.getItem("userId")
      ) {
        navigate("/login-page", { replace: true });
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }, [navigate]);

  return null;
};

export default Logout;
