import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth";

const ProtectedRoute = ({ children }) => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(
    isAuthenticated()
  );

  useEffect(() => {
    console.log("Auth Status:", isAuthenticatedState);
    setIsAuthChecked(true);
  }, [isAuthenticatedState]);

  if (!isAuthChecked) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticatedState) {
    console.log("Redirecting to login...");
    return <Navigate to="/login-page" />;
  }

  return children;
};

export default ProtectedRoute;
