import Dashboard from "./views/Pages/Dashboard.jsx";
import TemplatePages from "./views/Pages/TemplatePages.jsx";
import Pages from "./views/Pages/Pages.jsx";
import EditImage from "./views/Pages/EditImage.js";
import Profile from "./views/Pages/Profile.js";
import Settings from "./views/Pages/Settings.js";
import BackupLeads from "./views/Pages/BackupLeads.js";
import Logout from "./views/Pages/Logout.js";
import PageDetail from "./views/Pages/PageDetail.jsx";
import EditAsHTML from "./views/Pages/EditAsHTML.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/template_pages",
    name: "Templates",
    icon: "pe-7s-note2",
    component: TemplatePages,
    layout: "/admin",
  },
  {
    path: "/pages",
    name: "Pages",
    icon: "pe-7s-photo-gallery",
    component: Pages,
    layout: "/admin",
  },
  {
    path: "/backupLeads",
    name: "Backup Leads",
    icon: "pe-7s-photo-gallery",
    component: BackupLeads,
    layout: "/admin",
  },
  {
    path: "/editImages",
    name: "Edit Image",
    icon: "pe-7s-photo-gallery",
    component: EditImage,
    layout: "/admin",
  },

  {
    path: "/profile",
    name: "Profile",
    icon: "pe-7s-user",
    component: Profile,
    layout: "/admin",
  },

  {
    path: "/settings",
    name: "Settings",
    icon: "pe-7s-config",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "pe-7s-back-2",
    component: Logout,
    layout: "/admin",
  },
  // {
  //   path: "/pageDetails",
  //   name: "Page Details",
  //   icon: "pe-7s-note",
  //   component: PageDetail,
  //   layout: "/admin",
  // },
  // {
  //   path: "/editAsHTML",
  //   name: "Edit as HTML",
  //   icon: "pe-7s-note",
  //   component: EditAsHTML,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
