import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { DataBaseMiddleware } from "../../store/middlewares";
import UploadTemplateModal from "../../components/Modals/UploadTemplateModal/UploadTemplateModal";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Colors } from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

// Loader overlay styles
const loadingOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999, // Ensure it overlays other content
};

class TemplatePages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlContent: " ",
      user_id: " ",
      showPopup: false,
      con: "",
      modal: false,
      isLoading: false, // Add a state to manage loading
      currentPage: 1, // Track current page
      itemsPerPage: 6, // Number of items per page
    };
  }

  componentDidMount() {
    const { GetTemplates } = this.props;
    this.setState({ isLoading: true }); // Show loader
    GetTemplates(localStorage.getItem("userId"));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templates !== this.props.templates) {
      this.setState({ isLoading: false }); // Hide loader after templates are updated
    }
  }

  deleteTemplate = async (templateId) => {
    const { templates } = this.props;
    this.setState({ isLoading: true });

    try {
      await axios.post(
        `https://magicpages.propstory.com/template_pages/remove`,
        {
          template_id: templateId,
        }
      );
      templates(localStorage.getItem("userId"));
    } catch (error) {
      console.error("Error deleting page:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { templates } = this.props;
    const { isLoading } = this.state; // Destructure isLoading from state

    const templatesArray = Array.isArray(templates) ? templates : [];

    const { currentPage, itemsPerPage } = this.state;

    // Calculate the indices for pagination
    const indexOfLastTemplate = currentPage * itemsPerPage;
    const indexOfFirstTemplate = indexOfLastTemplate - itemsPerPage;

    // Slice the templates for the current page
    const currentTemplates = templatesArray.slice(
      indexOfFirstTemplate,
      indexOfLastTemplate
    );
    const totalPages = Math.ceil(templatesArray.length / itemsPerPage);

    return (
      <div className="content" style={{ position: "relative" }}>
        <style>
          {" "}
          {`
          .pagination {
            margin-top: 20px !important;
            font-size: 1rem !important;
          }

          .page-item.active .page-link {
            background-color: #007bff !important;
            border-color: #007bff !important;
            color: #fff !important;
          }

          .page-item .page-link {
            color: #007bff !important;
            transition: all 0.2s !important;
          }

          .page-item .page-link:hover {
            background-color: #f1f1f1 !important;
            color: #0056b3 !important;
          }

          .page-item.disabled .page-link {
            color: #6c757d !important;
            background-color: #fff !important;
            border-color: #dee2e6 !important;
          }
        `}
        </style>
        {isLoading && (
          <div style={loadingOverlayStyle}>
            <CircularProgress size={100} style={{ color: "#F50157" }} />
          </div>
        )}
        <Container fluid className="text-center">
          <Row className="text-center">
            <Col md={12}>
              <h2
                style={{
                  fontWeight: "Bold",
                  color: "black",
                  fontFamily: "Montserrat",
                  letterSpacing: 2,
                }}
              >
                Templates
              </h2>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {currentTemplates.length > 0 ? (
              currentTemplates.map((item, index) => (
                <Col
                  lg={4}
                  sm={8}
                  className="d-flex justify-content-center mb-4"
                  key={index}
                >
                  <StatsCard
                    statsColor={Colors.Black}
                    statsHeading={item.name}
                    statsBtn1Text="View Template"
                    statsBtn2Text="Clone Template"
                    frame={item.html} // Pass frame prop here
                    statsHTML={item.html} // Pass statsHTML prop for modals
                    onClickDelete={() => this.deleteTemplate(item._id)}
                  />
                </Col>
              ))
            ) : (
              <p>No templates available</p>
            )}
          </Row>
          <nav aria-label="Page navigation example">
            <ul className={`pagination justify-content-center`}>
              {/* Previous Button */}
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() =>
                    this.setState({ currentPage: currentPage - 1 })
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>

              {/* Dynamically Render Page Numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => this.setState({ currentPage: index + 1 })}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              {/* Next Button */}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() =>
                    this.setState({ currentPage: currentPage + 1 })
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </Container>

        <UploadTemplateModal
          id={localStorage.getItem("userId")}
          modal={this.state.modal}
        />
      </div>
    );
  }
}

const mapStateToProps = (states) => ({
  userInfo: states.AuthReducer.user,
  userId: states.AuthReducer.user.id,
  templates: states.DataBaseReducer.templates,
});

const mapDispatchToProps = (dispatch) => ({
  GetTemplates: (userId) => {
    dispatch(DataBaseMiddleware.GetTemplates(userId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePages);
