import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { DataBaseMiddleware } from "../../store/middlewares";
import { StatsCard } from "../../components/StatsCard/StatsCardPage";
import { Colors } from "../../config";
import { useNavigate } from "react-router-dom";
import noPage from "../../assets/img/NoPage.png";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

const loadingOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999, // Ensure it overlays other content
};

const Pages = ({ GetPages, pages }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [loading, setLoading] = useState(false); // State for managing loading status

  useEffect(() => {
    GetPages(localStorage.getItem("userId"));

    return () => {
      console.log("Component will unmount");
    };
  }, [GetPages]);

  const deletePage = (pageId) => {
    setLoading(true); // Set loading to true when the delete request starts

    axios
      .post(`https://magicpages.propstory.com/page/remove`, {
        page_id: pageId,
      })
      .then(() => {
        GetPages(localStorage.getItem("userId"));
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  const indexOfLastPage = currentPage * itemsPerPage;
  const indexOfFirstPage = indexOfLastPage - itemsPerPage;
  const currentPages = pages.slice(indexOfFirstPage, indexOfLastPage);
  const totalPages = Math.ceil(pages.length / itemsPerPage);

  return (
    <div className="content" style={{ position: "relative" }}>
      <style>
        {`
          .pagination {
            margin-top: 20px !important;
            font-size: 1rem !important;
          }

          .page-item.active .page-link {
            background-color: #007bff !important;
            border-color: #007bff !important;
            color: #fff !important;
          }

          .page-item .page-link {
            color: #007bff !important;
            transition: all 0.2s !important;
          }

          .page-item .page-link:hover {
            background-color: #f1f1f1 !important;
            color: #0056b3 !important;
          }

          .page-item.disabled .page-link {
            color: #6c757d !important;
            background-color: #fff !important;
            border-color: #dee2e6 !important;
          }
        `}
      </style>
      <Container fluid className="text-center">
        <Row>
          {currentPages.length <= 0 ? (
            <img
              alt="no page"
              src={noPage}
              width="60%"
              style={{
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            />
          ) : (
            currentPages.map((item, index) => (
              <Col lg={4} sm={8} key={item._id}>
                <StatsCard
                  key={index}
                  MainImage={<img alt="" src={item.image} />}
                  statsColor={Colors.LightBlue}
                  statsHeading={item.name}
                  frame={item.html}
                  statsBtn2Text="Delete Page"
                  statsBtn1Text="Edit Page"
                  onClickEdit={() => {
                    navigate({
                      pathname: "/admin/pageDetails",
                      search: `?page_id=${item._id}`,
                      state: { item },
                    });
                  }}
                  onClickDelete={() => deletePage(item._id)}
                />
              </Col>
            ))
          )}
        </Row>

        {/* Display Loader when deleting a page */}
        {loading && (
          <div style={loadingOverlayStyle}>
            <CircularProgress size={100} style={{ color: "#F50157" }} />
          </div>
        )}

        <nav aria-label="Page navigation example">
          <ul className={`pagination justify-content-center`}>
            {/* Previous Button */}
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {/* Dynamically Render Page Numbers */}
            {Array.from({ length: totalPages }, (_, index) => (
              <li
                key={index + 1}
                className={`page-item ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}

            {/* Next Button */}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </Container>
    </div>
  );
};

const mapStateToProps = (states) => ({
  userId: states.AuthReducer.user.id,
  pages: states.DataBaseReducer.pages,
});

const mapDispatchToProps = (dispatch) => ({
  GetPages: (userId) => {
    dispatch(DataBaseMiddleware.GetPages(userId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
