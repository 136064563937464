import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import axios from "axios";
import AuthMiddleware from "../../store/middlewares/AuthMiddleware";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import AppTheme from "./theme/AppTheme.tsx";
import { Link, Navigate, useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const SignUpPage = ({ SignUp }) => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [signedUp, setSignedUp] = useState(false);
  const [error, setError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);

  useEffect(() => {
    axios.get("https://magicpages.propstory.com/getCompanies").then((res) => {
      setCompanies(res.data.companies);
    });
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePassword = (password) => password.length >= 6; // Example: At least 6 characters

  const handleSignUp = (e) => {
    e.preventDefault();

    if (!fullName || !email || !password || !confirmPassword) {
      setError("Please fill all fields");
      setErrorOpen(true);
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      setErrorOpen(true);
      return;
    }

    if (!validatePassword(password)) {
      setError("Password must be at least 6 characters long");
      setErrorOpen(true);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setErrorOpen(true);
      return;
    }

    SignUp(fullName, email, password, company);
    setSignedUp(true);
    setTimeout(() => {
      navigate("/login-page");
    }, 2000);
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSignedUp(false);
    setErrorOpen(false);
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(1.2rem, 10vw, 1.2rem)" }}
          >
            Welcome to Magicpages!
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel>Full Name</FormLabel>
              <TextField
                type="text"
                name="name"
                placeholder="John Doe"
                required
                fullWidth
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <TextField
                type="email"
                name="email"
                placeholder="your@email.com"
                required
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Company</FormLabel>
              <Input
                type="select"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              >
                <option value="">-- Select Company --</option>
                {companies.map((company) => (
                  <option key={company._id} value={company._id}>
                    {company.name}
                  </option>
                ))}
              </Input>
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <TextField
                type="password"
                name="password"
                placeholder="••••••"
                required
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Confirm Password</FormLabel>
              <TextField
                type="password"
                name="confirmPassword"
                placeholder="••••••"
                required
                fullWidth
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSignUp}
            >
              Sign up
            </Button>
          </Box>
          <Divider>or</Divider>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography sx={{ textAlign: "center" }}>
              Already have an account?{" "}
              <Link
                to="/login-page"
                variant="body2"
                sx={{ alignSelf: "center" }}
              >
                Sign in
              </Link>
            </Typography>
          </Box>
        </Card>
      </SignInContainer>
      <Snackbar open={signedUp} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          You have signed up successfully! Please login now.
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </AppTheme>
  );
};

const mapDispatchToProps = (dispatch) => ({
  SignUp: (fullName, email, password, company) =>
    dispatch(AuthMiddleware.SignUp(fullName, email, password, company)),
});

export default connect(null, mapDispatchToProps)(SignUpPage);
