import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import API from "../../config/api/index";
import LaptopIcon from "@material-ui/icons/Laptop";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DataBaseMiddleware } from "../../store/middlewares/index.js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LinkIcon from "@material-ui/icons/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import google from "../../assets/img/google.png";
import facebook from "../../assets/img/facebook.png";
import taboola from "../../assets/img/taboola.png";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
import existingImages from "../../config/images/s3images";
import CodeIcon from "@material-ui/icons/Code";
import { Link } from "react-router-dom";
// import Dev_Script from '../../components/Dev Script/Dev_Script'
// const API = require("API");
let originalScriptsSet = new Set();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PageDetail extends Component {
  constructor(props) {
    super(props);

    // DIFFERENT STATE VARIABLES
    this.state = {
      data: [],
      mounted: false,
      selectedFile: null,
      code: null,
      encoded: null,
      pageId: null,
      name_input: "",
      file: "",
      imageIsBig: false,
      imagePreviewUrl: "",
      frameWidth: "100%",
      btnClassM: "disabled",
      btnClassD: "secondary",
      //   Form Data
      meta_keyword: "",
      meta_description: "",
      page_title: "",
      favicon_icon: "",
      location: "",
      google_webhook: "",
      facebook_webhook: "",
      taboola_webhook: "",
      google_conversion_pixel: "",
      facebook_conversion_pixel: "",
      other_conversion_pixel: "",
      utmId: "",
      loadingiframe: true,
      pageName: "",
      pageLinks: [],
      // Form Data Ends
      clicked: "",
      imgClass: "",
      margTop: 0,
      isSaved: false,
      anchorEl: null,
      urlToCopy: "",
      loadingLink: false,
      originalHTML: null,
      mobile_calling: "",
      imageModal: false,
      imagesforModal: [],
      disable: true,
      //   code: this.state.data.html
    };
    this.onClickMobile = this.onClickMobile.bind(this);
    this.onClickDesktop = this.onClickDesktop.bind(this);
    // this.handleFileRead = this.handleFileRead.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseBigImage = this.handleCloseBigImage.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.onSave = this.onSave.bind(this);
    this.enableEditableImages = this.enableEditableImages.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.fetchPage = this.fetchPage.bind(this);
    this.loadingIframe = this.loadingIframe.bind(this);
    this.closeImageModal = this.closeImageModal.bind(this);
    this.handleImageModal = this.handleImageModal.bind(this);
    this.handleChooseImage = this.handleChooseImage.bind(this);
  }

  loadingIframe = () => {
    this.setState({ loadingiframe: false, disable: false });
  };

  closeImageModal = () => {
    this.setState({ imageModal: false });
  };

  handleFaviconModal = () => {
    this.setState({ imageModal: true });
    API.post("images", {
      type: "favicon",
    }).then((result) => {
      this.setState({ imagesforModal: result.data.images });
    });
  };

  handleImageModal = () => {
    this.setState({ imageModal: true });
    API.post("images", {
      type: "image",
    }).then((result) => {
      this.setState({ imagesforModal: result.data.images });
    });
  };

  handleChooseImage = (imageType, url) => {
    let imageToBeChanged = document
      .getElementById("frame")
      .contentWindow.document.body.getElementsByClassName("img101");
    if (imageType == "favicon") {
      this.setState({ favicon_icon: url });
    } else {
      this.setState({
        imagePreviewUrl: url,
      });

      if (this.state.frameWidth === "100%") {
        $(imageToBeChanged).attr("desktopurl", url);
        $(imageToBeChanged).attr("src", url);
      } else {
        $(imageToBeChanged).attr("mobileurl", url);
        $(imageToBeChanged).attr("src", url);
      }
    }
    this.setState({ imageModal: false });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
  };

  enableEditableImages = () => {
    // this.addEditImageModal();
    // this.addEditImageModalEvents();
    // this.addImageChangeBtn();
    let that = this;
    //var allImages = window.frames['frame'].contentDocument.getElementByTagName('img')[0];
    //console.log($('#frame').contents().find('img'))
    let allImages = $("#frame").contents().find("img");
    $.each(allImages, function (index, img) {
      $(img).hover(
        function () {
          $(this).append($(".middle-btn-container"));
          $(".middle-btn-container").css({
            opacity: "1",
          });
          $(img).css({
            opacity: ".7",
          });
          $(".change-img-btn").data("img", $(img));
        },
        function () {
          $(".middle-btn-container").css({
            opacity: "0",
          });
          $(img).css({
            opacity: "1",
          });
        }
      );

      var source = $(this);

      $(img).click(function () {
        that.setState({
          imagePreviewUrl: source.attr("src"),
          clicked: source.attr("src"),
        });
        if (
          document
            .getElementById("frame")
            .contentWindow.document.body.getElementsByClassName("img101")
            .length > 0
        ) {
          document
            .getElementById("frame")
            .contentWindow.document.body.querySelector(".img101")
            .classList.remove("img101");
          source.addClass("img101");
        } else {
          source.addClass("img101");
        }
      });

      $(".change-img-btn").on("click", function (e) {
        $("#imageModal").css({
          display: "block",
        });
        let img = $(this).data("img");
        let mobileUrl =
          img.attr("mobileurl") !== undefined
            ? img.attr("mobileurl")
            : img.attr("src");
        let desktopUrl =
          img.attr("desktopurl") !== undefined
            ? img.attr("desktopurl")
            : img.attr("src");
        $(".modal-desktop-img").attr("src", desktopUrl);
        $(".modal-mobile-img").attr("src", mobileUrl);
        $(".modal-desktop-img").data("act-img", img);
        $(".modal-mobile-img").data("act-img", img);
      });
    });
  };

  show_input_values = (hidden_field_name, field_name) => {
    let hidden_field_val = $("." + hidden_field_name).val();
    $("." + field_name).val(hidden_field_val);
  };

  UNSAFE_componentWillMount = async () => {
    this.setState({ mounted: true });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page_id = urlParams.get("page_id");
    await this.setState({ pageId: page_id });
    this.fetchPage();
    this.fetchUTM();
    var content =
      document.getElementsByTagName("iframe").frame.contentWindow.document
        .documentElement;
    console.log("CONTENT");
    console.log(typeof content);
  };

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  fetchPage() {
    API.post(`getPage`, {
      page_id: this.state.pageId,
    }).then((res) => {
      var htmlen = res.data.page.html;
      const decoded = decodeURIComponent(escape(window.atob(htmlen)));
      this.setState({
        code: decoded,
        originalHTML: decoded,
        pageName: res.data.page.name,
        pageLinks: res.data.page.links[0],
      });
    });

    if (this.state.mounted) {
      setInterval(() => {
        const frame = document.getElementById("frame");
        if (frame && frame.contentWindow) {
          $(frame.contentWindow.document.body).prop("contentEditable", "true");
          // this.create_custom_input_fields();
          this.enableEditableImages();
        }
      }, 2000);
    }

    $().ready(function () {
      $("#frame").ready(function () {
        //The function below executes once the iframe has finished loading
        let allImages = $("img").not(".modal-desktop-img, .modal-mobile-img");

        $.each(allImages, function (index, img) {
          $(img).hover(
            function () {
              $(this).append($(".middle-btn-container"));
              $(".middle-btn-container").css({
                opacity: "1",
              });
              $(img).css({
                opacity: ".7",
              });
              $(".change-img-btn").data("img", $(img));
            },
            function () {
              $(".middle-btn-container").css({
                opacity: "0",
              });
              $(img).css({
                opacity: "1",
              });
            }
          );

          $(".change-img-btn").on("click", function (e) {
            $("#imageModal").css({
              display: "block",
            });
            let img = $(this).data("img");
            let mobileUrl =
              img.attr("mobileurl") !== undefined
                ? img.attr("mobileurl")
                : img.attr("src");
            let desktopUrl =
              img.attr("desktopurl") !== undefined
                ? img.attr("desktopurl")
                : img.attr("src");
            $(".modal-desktop-img").attr("src", desktopUrl);
            $(".modal-mobile-img").attr("src", mobileUrl);
            $(".modal-desktop-img").data("act-img", img);
            $(".modal-mobile-img").data("act-img", img);
          });
        });
      });
    });
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isSaved: false });
  }
  handleCloseBigImage(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ imageIsBig: false });
  }

  removeScripts(elementType) {
    var htmlparse = new DOMParser();
    var htmlorg = htmlparse.parseFromString(
      this.state.originalHTML,
      "text/html"
    );
    var originContent = htmlorg.getElementsByTagName(elementType);

    var modifiedScript = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByTagName(
        elementType
      );

    console.log(elementType + " being removed", Date.now());
    // let originalScriptsSet = new Set();

    $(originContent)
      .filter(elementType)
      .each(function (index, originalScript) {
        originalScriptsSet.add(originalScript.outerHTML);
      });

    $(modifiedScript).each(function (index, currentScript) {
      console.info("Checking for script", currentScript);
      if ($(currentScript).attr("id") === "development_script") {
        console.log("Not removing because of exclude", currentScript);
      } else {
        let exist = originalScriptsSet.has(currentScript.outerHTML);
        if (!exist) {
          console.info("script to be removed", currentScript.outerHTML);
          $(currentScript).remove();
        } else {
          console.log("Not removing", currentScript);
        }
      }
    });
    console.log(elementType + " removed", Date.now());
  }

  removeSrc() {
    var images = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByTagName("img");
    let allImages = $(images);
    $.each(allImages, function (index, img) {
      img = $(img);
      // let mobileUrl = img.attr("mobileurl") !== undefined ? img.attr("mobileurl") : img.attr("src");
      // let desktopUrl = img.attr("desktopurl") !== undefined ? img.attr("desktopurl") : img.attr("src");
      let mobileUrl = img.attr("mobileurl");
      let desktopUrl = img.attr("desktopurl");
      if (mobileUrl !== undefined && mobileUrl !== "") {
        img.attr("mobileurl", mobileUrl);
      }
      if (desktopUrl !== undefined && desktopUrl !== "") {
        img.attr("desktopurl", desktopUrl);
      }

      if (
        desktopUrl !== undefined &&
        desktopUrl !== "" &&
        mobileUrl !== undefined &&
        mobileUrl !== ""
      ) {
        img.attr("src", "");
      }
    });
    this.setState({
      imagePreviewUrl: "",
    });
  }

  // onEditAsHTML() {
  //   // const _this = this;
  //   let edithtmlurl = "/EditAsHTML/?page_id=";
  //   window.open(edithtmlurl, "_blank");
  // }

  // CHANGE FRAME VIEW FUNCTION
  onClickMobile() {
    this.onSave();
    document
      .getElementsByTagName("iframe")
      .frame.contentWindow.location.reload(true);
    this.setState({
      loadingiframe: true,
      frameWidth: "30%",
      btnClassM: "secondary",
      btnClassD: "disabled",
    });
  }

  onClickDesktop() {
    this.onSave();
    document
      .getElementsByTagName("iframe")
      .frame.contentWindow.location.reload(true);
    this.setState({
      loadingiframe: true,
      frameWidth: "100%",
      btnClassD: "secondary",
      btnClassM: "disabled",
    });
  }

  createLink = async () => {
    this.removeScripts();

    var content =
      document.getElementsByTagName("iframe").frame.contentWindow.document
        .documentElement.innerHTML;
    var encoded = btoa(unescape(encodeURIComponent(content)));

    await API.post(`createLink`, {
      page_name: this.state.pageName,
      page_id: this.state.pageId,
      page_html: encoded,
      page_link: this.state.pageLinks,
    }).then(
      this.setState({ loadingLink: true }),
      setTimeout(() => {
        this.setState({ loadingLink: false });
        window.location.reload();
      }, 3000)
    );
  };

  setUTM_inPage() {
    var htmlDoc =
      document.getElementsByTagName("iframe").frame.contentWindow.document
        .documentElement;
    $(htmlDoc)
      .find("head")
      .find("meta[name='description']")
      .attr("content", this.state.meta_description);
    $(htmlDoc)
      .find("head")
      .find("meta[name='keywords']")
      .attr("content", this.state.meta_keyword);
    $(htmlDoc)
      .find("head")
      .find(".title_hidden_header")
      .html(this.state.page_title);
    $(htmlDoc)
      .find("head")
      .find(".favicon")
      .attr("href", this.state.favicon_icon);

    // $(htmlDoc.head.querySelector("meta[name='description']").setAttribute("content", this.state.meta_description));
    // $(htmlDoc.head.querySelector("meta[name='keywords']").setAttribute("content", this.state.meta_keyword));
    // $(htmlDoc.head.getElementsByClassName("title_hidden_header")).html(this.state.page_title);
    // $(htmlDoc.head.getElementsByClassName("favicon")).attr("href",this.state.page_title);
    // const iframemap = document.getElementsByTagName("iframe").frame.contentWindow.document.documentElement.getElementsByClassName("map_location");
    // if (this.state.location !== "") {
    //   const _this = this;
    //   console.log('====================================');
    //   console.log(this.state.location);
    //   console.log($(htmlDoc).find(".map_location").attr("src"));
    //   console.log('====================================');
    //   // $(htmlDoc).find(".map_location").attr("src", function(val, i = _this.state.location){
    //   //   return val + i;
    //   // })
    //   iframemap.datasrc = this.state.location;
    //   console.log($(htmlDoc).find(".map_location").attr("src"));

    // }
  }

  // FETCH UTM DATA FUNCTION
  fetchUTM() {
    // Where we're fetching data from

    API.post(`getutmDetails`, {
      page_id: this.state.pageId,
    })
      // We get the API response and receive data in JSON format...
      .then((res) => {
        const utmData = res.data.utm;

        this.setState({
          utmId: utmData._id,
          meta_keyword: utmData.meta_keyword,
          meta_description: utmData.meta_description,
          page_title: utmData.page_title,
          favicon_icon: utmData.favicon_icon,
          location: utmData.location,
          mobile_calling: utmData.mobile_calling,
          google_webhook: utmData.google_webhook,
          facebook_webhook: utmData.facebook_webhook,
          taboola_webhook: utmData.taboola_webhook,
          google_conversion_pixel: utmData.google_conversion_pixel,
          facebook_conversion_pixel: utmData.facebook_conversion_pixel,
          other_conversion_pixel: utmData.other_conversion_pixel,
        });
      })

      // Catch any errors we hit and update the app
      .catch((error) =>
        this.setState({
          error,
          isLoading: false,
        })
      );
  }

  onSave = async () => {
    this.setState({ loadingLink: true, disable: true });
    this.removeScripts("script");
    this.removeScripts("noscript");
    this.removeSrc();
    this.setUTM_inPage();
    this.onSubmitForm();

    let flaginput = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByClassName(
        "intl-tel-input"
      );

    // console.log('====================================');
    // console.log(flaginput);
    // console.log('====================================');

    // let numOfPhone = flaginput.length;
    // for (var i = 0; i < numOfPhone; i++) {
    $(flaginput).remove();
    // }

    var phoneInput = document.createElement("input");
    phoneInput.setAttribute("type", "tel");
    phoneInput.setAttribute("class", "form-control phone");

    var phoneInput1 = document.createElement("input");
    phoneInput1.setAttribute("type", "tel");
    phoneInput1.setAttribute("class", "form-control phone");

    var phoneInput2 = document.createElement("input");
    phoneInput2.setAttribute("type", "tel");
    phoneInput2.setAttribute("class", "form-control phone");

    let form1 = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByClassName(
        "form1"
      );

    let form2 = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByClassName(
        "form2"
      );

    let form3 = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByClassName(
        "form3"
      );

    $(form1).find(".phone-open-div").append(phoneInput);
    if (form2) {
      $(form2).find(".phone-open-div").append(phoneInput1);
    }
    if (form3) {
      $(form3).find(".phone-open-div").append(phoneInput2);
    }

    let content =
      document.getElementsByTagName("iframe").frame.contentWindow.document
        .documentElement.innerHTML;
    let remove_edit_from_body = document
      .getElementsByTagName("iframe")
      .frame.contentWindow.document.documentElement.getElementsByTagName(
        "body"
      );
    $(remove_edit_from_body).attr("contenteditable", false);
    var encoded = btoa(unescape(encodeURIComponent(content)));
    console.log(content);
    // console.log(changeTitle);
    await API.post("page/update", {
      name: this.state.pageName,
      html: encoded,
      user_id: localStorage.getItem("userId"),
      page_id: this.state.pageId,
    });
    this.fetchPage();
    setTimeout(() => {
      this.setState({
        isSaved: true,
        loadingLink: false,
      });
    }, 5000);
  };

  // FILE UPLOAD FUNCTION
  fileReader = null;

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.fileReader = new FileReader();
    this.fileReader.onloadend = this.handleFileRead;

    this.fileReader.readAsText(event.target.files[0]);
  };

  handleChange(event) {
    this.setState({
      name_input: event.target.name_input,
    });
  }

  // IMAGE UPLOAD FUNCTION
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    let formData = new FormData();
    let imageSrc = document
      .getElementById("frame")
      .contentWindow.document.body.getElementsByClassName("img101");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    // Template name should come dynamically
    formData.append("templateName", "NAME");
    formData.append("file", file);
    if (file.size > 500000) {
      this.setState({ imageIsBig: true });
      // alert("The image you have uploaded is more than 300kb please compress and upload the image again");
      return;
    }
    API.post(`awsImageUpload`, formData, config)
      .then((res) => {
        this.setState({
          file: file,
          imagePreviewUrl: res.data.data.path,
        });

        if (this.state.frameWidth === "100%") {
          $(imageSrc).attr("desktopurl", res.data.data.path);
          $(imageSrc).attr("src", res.data.data.path);
        } else {
          $(imageSrc).attr("mobileurl", res.data.data.path);
          $(imageSrc).attr("src", res.data.data.path);
        }
      })
      .catch((error) => {
        alert(error);
      });
    reader.readAsDataURL(file);
  }

  // FORM SUBMIT FUNCTION
  onChangeForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  Dev_Script = () => {
    const {
      google_conversion_pixel,
      other_conversion_pixel,
      google_webhook,
      location,
      meta_keyword,
      meta_description,
      page_title,
      facebook_webhook,
      taboola_webhook,
      mobile_calling,
    } = this.state;

    let custom_fields = [
      { name: "google_conversion_pixel", value: google_conversion_pixel },
      { name: "taboola_conversion_pixel", value: other_conversion_pixel },
      // { name: 'ivr', value: null},
      { name: "mobile_calling", value: mobile_calling },
      // { name: 'desktop_calling', value: null },
      { name: "webhook_fb", value: facebook_webhook },
      { name: "webhook_taboola", value: taboola_webhook },
      // { name: 'webhook_linkedin',  },
      // { name: 'webhook_times',  },
      { name: "webhook_google", value: google_webhook },
      { name: "title", value: page_title },
      { name: "description", value: meta_description },
      { name: "keywords", value: meta_keyword },
      // { name: 'page_name', value: pageId },
      { name: "location", value: location },
    ];

    var allvar = "";

    custom_fields.forEach((variables) => {
      if (variables.value) {
        allvar +=
          "var " + variables.name + ` = "` + variables.value + `";` + "\n";
      }
    });
    return allvar;
  };

  onSubmitForm = () => {
    // get our form data out of state
    const {
      meta_keyword,
      meta_description,
      page_title,
      meta_author,
      favicon_icon,
      google_webhook,
      facebook_webhook,
      taboola_webhook,
      location,
      google_conversion_pixel,
      facebook_conversion_pixel,
      other_conversion_pixel,
      pageId,
      mobile_calling,
    } = this.state;

    let utmApi = this.state.utmId ? "utm/update" : "utm";
    API.post(utmApi, {
      meta_keyword,
      meta_description,
      page_title,
      meta_author,
      favicon_icon,
      google_webhook,
      facebook_webhook,
      taboola_webhook,
      location,
      mobile_calling,
      google_conversion_pixel,
      facebook_conversion_pixel,
      other_conversion_pixel,
      page: pageId,
    });

    var page_html =
      document.getElementsByTagName("iframe").frame.contentWindow.document
        .documentElement;
    $(page_html).find("#development_script").empty();
    $(page_html).find("#development_script").html(this.Dev_Script);
  };

  onUpdatePage = (e) => {
    e.preventDefault();
    const { html, _id, name } = this.props.location.state;
    const userId = this.props.userInfo.id;
    this.props.UpdatePage({
      name,
      html,
      userId,
      _id,
    });
  };

  onChangeForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    // console.log("USER", this.props.userInfo);
    // console.log("name", name);

    const uploadButton = {
      display: "none",
    };
    const labelColor = {
      color: "white",
      backgroundColor: "grey",
    };

    const open = Boolean(this.state.anchorEl);
    const id = open ? "link-popover" : undefined;

    // UPLOAD AND CHANGE IMAGE FUNCTION
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = (
        <img alt="preview" width="100%" height="200" src={imagePreviewUrl} />
      );
    } else {
      imagePreview = (
        <img
          alt="not selected"
          width="100%"
          height="200"
          src="https://cdn.propstory.com/magicpages/NAME/16iwl4k3txm1upzc2fplaceholder-image.png"
        />
      );
    }

    $(document).on("click", ".change-img-btn", function (e) {
      $("#imageModal").css({
        display: "block",
      });
      let img = $(this).data("img");
      let mobileUrl =
        img.attr("mobileurl") !== undefined
          ? img.attr("mobileurl")
          : img.attr("src");
      let desktopUrl =
        img.attr("desktopurl") !== undefined
          ? img.attr("desktopurl")
          : img.attr("src");
      $(".modal-desktop-img").attr("src", desktopUrl);
      $(".modal-mobile-img").attr("src", mobileUrl);
      $(".modal-desktop-img").data("act-img", img);
      $(".modal-mobile-img").data("act-img", img);
    });

    // if(document.getElementById("frame") != null){

    // }
    // else  {<UploadTemplateModal />}
    return (
      <>
        <Backdrop open={this.state.loadingLink} style={{ zIndex: 9 }}>
          <CircularProgress style={{ color: "#fff" }} />
          <h3 style={{ marginBottom: 0, color: "#fff", marginLeft: 10 }}>
            Saving your page.
          </h3>
        </Backdrop>

        <div className="content mb-5" style={{ padding: 20 }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item sm={3} md={3}>
              <h3
                style={{
                  fontWeight: "light",
                  fontFamily: "Montserrat",
                  color: "#000",
                  marginBottom: 0,
                }}
              >
                {this.state.pageName}
              </h3>
            </Grid>

            <Grid container lg justify="flex-end">
              {/* CHANGE DESKTOP AND MOBILE VIEW BUTTON */}
              <Link
                to={"/admin/editAsHTML/?page_id=" + this.state.pageId}
                target="_blank"
              >
                <Tooltip title="Edit As HTML" aria-label="edit-as-html">
                  <ToggleButton
                    disabled={this.state.disable}
                    aria-describedby={id}
                    // onClick={this.onEditAsHTML}
                    style={{
                      backgroundColor: "#dfdfdf",
                      outline: "none",
                      border: "none",
                      margin: 5,
                    }}
                    value="Create Link"
                  >
                    <CodeIcon color="action" />
                  </ToggleButton>
                </Tooltip>
              </Link>
              <Tooltip title="Create Link" aria-label="create-link">
                <ToggleButton
                  disabled={this.state.disable}
                  aria-describedby={id}
                  onClick={this.handleClick}
                  style={{
                    backgroundColor: "#dfdfdf",
                    outline: "none",
                    border: "none",
                    margin: 5,
                  }}
                  value="Create Link"
                >
                  <LinkIcon color="action" />
                </ToggleButton>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {this.state.loadingLink ? (
                  <div className="d-flex align-items-center m-3">
                    <p
                      style={{
                        marginBottom: 0,
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      Creating Link.{" "}
                      <span role="img" aria-label="waiting">
                        🕖
                      </span>
                    </p>
                  </div>
                ) : (
                  <div style={{ padding: 10 }}>
                    {this.state.pageLinks && (
                      <div>
                        <div className="d-flex align-items-center justify-content-between mb-2 links">
                          <img alt="google link" src={google} width="16" />
                          <h5>{this.state.pageLinks.google}</h5>
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(
                                this.state.pageLinks.google
                              );
                            }}
                          >
                            <FileCopyIcon color="primary" fontSize="small" />
                          </button>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2 links">
                          <img alt="taboola link" src={taboola} width="16" />
                          <h5>{this.state.pageLinks.taboola}</h5>
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(
                                this.state.pageLinks.taboola
                              );
                            }}
                          >
                            <FileCopyIcon color="primary" fontSize="small" />
                          </button>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2 links">
                          <img alt="facebook link" src={facebook} width="16" />
                          <h5>{this.state.pageLinks.fb}</h5>
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(
                                this.state.pageLinks.fb
                              );
                            }}
                          >
                            <FileCopyIcon color="primary" fontSize="small" />
                          </button>
                        </div>
                      </div>
                    )}
                    {this.state.pageLinks === undefined ? (
                      <button
                        onClick={this.createLink}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#654062",
                          fontWeight: "500",
                        }}
                      >
                        Create Links
                      </button>
                    ) : (
                      <button
                        onClick={this.createLink}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#654062",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Update Links
                      </button>
                    )}
                  </div>
                )}
              </Popover>
              <ToggleButtonGroup value={this.state.format} aria-label="device">
                <Tooltip title="Desktop View" aria-label="desktop">
                  <ToggleButton
                    disabled={this.state.disable}
                    value="laptop"
                    aria-label="laptop"
                    onClick={this.onClickDesktop}
                    style={{
                      backgroundColor: "#dfdfdf",
                      outline: "none",
                      border: "none",
                      margin: 5,
                    }}
                  >
                    <LaptopIcon color={this.state.btnClassD} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Mobile View" aria-label="mobile">
                  <ToggleButton
                    disabled={this.state.disable}
                    value="phone"
                    aria-label="phone"
                    onClick={this.onClickMobile}
                    style={{
                      backgroundColor: "#dfdfdf",
                      outline: "none",
                      border: "none",
                      margin: 5,
                    }}
                  >
                    <PhoneAndroidIcon color={this.state.btnClassM} />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
              <button
                disabled={this.state.disable}
                type="submit"
                className="btn"
                onClick={this.onSave}
                style={{
                  margin: 5,
                  backgroundColor: "#654062",
                  fontWeight: "normal",
                }}
              >
                Save Page
              </button>
            </Grid>
          </Grid>

          <Grid container justify="space-between">
            <Grid item xs={10}>
              {this.state.loadingiframe ? (
                <Skeleton
                  className="iframePlace"
                  variant="rect"
                  width={this.state.frameWidth}
                  style={{ top: window.innerWidth < 1217 ? "45%" : "50%" }}
                  height="100%"
                />
              ) : null}

              <iframe
                onLoad={this.loadingIframe}
                title="Edit View"
                style={{
                  top: window.innerWidth < 1217 ? "45%" : "50%",
                }}
                className="mt-4 iframePlace"
                width={this.state.frameWidth}
                height={this.state.loadingiframe ? "0%" : "100%"}
                id="frame"
                srcDoc={this.state.code}
              />
            </Grid>
            {/* IFRAME ENDS HERE */}

            {/* FORM FOR UTM DETAILS */}
            <Grid item xs={2}>
              <div className="card ml-3 mt-4 p-3" style={{ borderRadius: 12 }}>
                <div className="card-block">
                  <div className="previewComponent">{imagePreview}</div>
                  <h5
                    style={{
                      fontSize: 12,
                      fontWeight: 700,
                      marginBottom: "auto",
                    }}
                  >
                    Select an image from page to change.
                  </h5>
                  <div className="d-flex justify-content-between">
                    <form encType="multipart/form-data">
                      <label style={labelColor} className="btn">
                        <input
                          name="image_file"
                          id="image_file"
                          style={uploadButton}
                          className="fileInput"
                          type="file"
                          accept="image"
                          onChange={(e) => this._handleImageChange(e)}
                        />
                        Choose & Upload
                      </label>
                    </form>
                    <h4 style={{ margin: "auto", padding: 4 }}> OR </h4>
                    <button
                      className="btn btn-info"
                      onClick={this.handleImageModal}
                    >
                      Choose Generic Image
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleFaviconModal}
                    >
                      Choose Favicon
                    </button>
                  </div>
                </div>
              </div>
              <div className="card p-3 ml-3" style={{ borderRadius: 12 }}>
                <div className="card-block">
                  <form className="form" onSubmit={this.onSubmitForm}>
                    <fieldset>
                      <legend>PAGE DETAILS</legend>
                      {/* <label className="k-form-field">
                        <span>Favicon Icon </span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="favicon_icon"
                        value={this.state.favicon_icon}
                        className="k-textbox"
                        placeholder="Favicon Icon"
                        disabled
                      />
                      <br /> */}
                      <label className="k-form-field">
                        <span>Location For Map</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="location"
                        value={this.state.location}
                        className="k-textbox"
                        placeholder="Location iframe"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Page Title</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="page_title"
                        value={this.state.page_title}
                        className="k-textbox"
                        placeholder="Page Title"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Google Webhook</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="google_webhook"
                        value={this.state.google_webhook}
                        className="k-textbox"
                        placeholder="Google Webhook"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Facebook Webhook</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="facebook_webhook"
                        value={this.state.facebook_webhook}
                        className="k-textbox"
                        placeholder="Facebook Webhook"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Taboola Webhook</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="taboola_webhook"
                        value={this.state.taboola_webhook}
                        className="k-textbox"
                        placeholder="Taboola Webhook"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Meta Keyword</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="meta_keyword"
                        value={this.state.meta_keyword}
                        className="k-textbox"
                        placeholder="Meta Keyword"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Meta Description</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="meta_description"
                        value={this.state.meta_description}
                        className="k-textbox"
                        placeholder="Meta Description"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Google Conversion Pixel</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="google_conversion_pixel"
                        value={this.state.google_conversion_pixel}
                        className="k-textbox"
                        placeholder="Google Conversion Pixel"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Facebook Conversion Pixel</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="facebook_conversion_pixel"
                        value={this.state.facebook_conversion_pixel}
                        className="k-textbox"
                        placeholder="Facebook Conversion Pixel"
                      />
                      <br />
                      <label className="k-form-field">
                        <span>Taboola Conversion Pixel</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="other_conversion_pixel"
                        value={this.state.other_conversion_pixel}
                        className="k-textbox"
                        placeholder="Taboola Conversion Pixel"
                      />
                      <br />

                      <label className="k-form-field">
                        <span>Page Name</span>
                      </label>
                      <br />
                      <input
                        onChange={this.onChangeForm}
                        name="pageName"
                        value={this.state.pageName}
                        className="k-textbox"
                        placeholder="Page Name"
                      />
                    </fieldset>
                    <br />
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* FORM EDS HERE */}
          {/* CHANGE IFRAME IMAGES OR ITS SIZE */}

          {/* <div style={{ display: "none" }} id="utm">
          {Object.entries(this.state.fetchedData).map(function ([key, value]) {
            return <div> {`${key}=\"${value}\"`}</div>;
          })}
        </div> */}
          <Snackbar
            open={this.state.isSaved}
            autoHideDuration={1000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="success">
              Your Page is saved successfully.
              <span role="img" aria-label="super">
                👌
              </span>
            </Alert>
          </Snackbar>

          <Snackbar
            open={this.state.imageIsBig}
            autoHideDuration={10000}
            onClose={this.handleCloseBigImage}
          >
            <Alert onClose={this.handleCloseBigImage} severity="error">
              The image you have uploaded is more than 500kb please compress and
              upload the image again.{" "}
              <span role="img" aria-label="super">
                😢
              </span>
            </Alert>
          </Snackbar>
          <Modal
            aria-labelledby="Choose-Image"
            open={this.state.imageModal}
            onClose={this.closeImageModal}
            closeAfterTransition
            className="imageSelectorModal"
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.imageModal}>
              <div className="imageSelectorFrame d-flex row justify-content-evenly">
                s
                {this.state.imagesforModal.map((image) => {
                  const imageUrl = existingImages.awsUrl + image.name;
                  return (
                    <div
                      className="col-4 mb-4 chooseModalImage"
                      onClick={() =>
                        this.handleChooseImage(image.type, imageUrl)
                      }
                    >
                      <img
                        src={existingImages.awsUrl + image.name}
                        width={image.type == "image" ? "400" : "100"}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  );
                })}
              </div>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(states) {
  return { userInfo: states.AuthReducer.user };
}

function mapDispatchToProps(dispatch) {
  return {
    UpdatePage: (payload) => {
      dispatch(DataBaseMiddleware.UpdatePage(payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageDetail);
